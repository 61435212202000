import { get as getByDotNotation } from "lodash";
export var getFormattedAddress = function (values, lineBreak) {
    var address = [
        getByDotNotation(values, "venue.address.addressLine1") || null,
        getByDotNotation(values, "venue.address.addressLine2") || null,
        getByDotNotation(values, "venue.address.addressLine3") || null,
        getByDotNotation(values, "venue.address.county") || null,
        getByDotNotation(values, "venue.address.postcode") || null,
    ].filter(function (line) { return line !== null; });
    return address.join(lineBreak ? '<br />' : ', ');
};
export var getFormattedAddressFromObject = function (venue, lineBreak) {
    if (venue === null || typeof venue === 'undefined')
        return '';
    var address = [
        venue.addressLine1 || null,
        venue.addressLine2 || null,
        venue.addressLine3 || null,
        venue.county || null,
        venue.postcode || null,
    ].filter(function (line) { return line !== null; });
    return address.join(lineBreak ? '<br />' : ', ');
};
