var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { lazy, Suspense } from "react";
import { Field } from "formik";
import { getGroupClass } from "../lib/GroupClass";
import VenueOverview from "./VenueOverview";
var VenueSearch = lazy(function () { return import('./VenueSearch'); });
var UserForm = /** @class */ (function (_super) {
    __extends(UserForm, _super);
    function UserForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            addressSelected: false,
            showForm: true,
            showSummary: false,
            showComplete: false,
            proceedCheck: false,
            manualAddressInput: false,
            venueAlreadyExists: false,
            venueExistsURL: '',
            venue: null
        };
        _this.allergenSelection = React.createRef();
        _this.imageUploader = React.createRef();
        _this.venueAddress = React.createRef();
        _this.dateSelection = React.createRef();
        _this.firstFieldset = React.createRef();
        _this.venueOverview = React.createRef();
        _this.affixNav = function () {
            var _a, _b, _c, _d, _e;
            var rect = (_a = _this.firstFieldset) === null || _a === void 0 ? void 0 : _a.current.getBoundingClientRect();
            if (rect.top < 1) {
                (_c = (_b = _this.venueOverview) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.setState({ isAffixed: true });
            }
            else {
                (_e = (_d = _this.venueOverview) === null || _d === void 0 ? void 0 : _d.current) === null || _e === void 0 ? void 0 : _e.setState({ isAffixed: false });
            }
        };
        _this.resetElements = function () {
            var _a, _b, _c, _d;
            (_b = (_a = _this.allergenSelection) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.resetSelection();
            (_d = (_c = _this.imageUploader) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.resetUploader(false);
        };
        _this.venueSelected = function (venue) {
            var _a;
            _this.setState({ addressSelected: true, venue: venue });
            (_a = _this.venueAddress.current) === null || _a === void 0 ? void 0 : _a.setComplete(true);
        };
        _this.venueCleared = function () {
            var _a, _b, _c, _d, _e, _f;
            _this.setState({ addressSelected: false });
            (_b = (_a = _this.venueAddress) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setComplete(false);
            (_d = (_c = _this.imageUploader) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.resetUploader(true);
            (_f = (_e = _this.dateSelection) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.resetSelection();
        };
        _this.triggerGooglePhotoUpload = function (photo) {
            var _a, _b;
            (_b = (_a = _this.imageUploader) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.uploadGooglePhoto(photo);
        };
        _this.triggerNavChange = function () {
            document.body.dispatchEvent(new Event('venueForm.completed'));
        };
        _this.showSummary = function (values, props) {
            _this.setState({
                proceedCheck: false,
                showSummary: true,
                showForm: false
            }, function () { return props.setSubmitting(false); });
        };
        _this.checkValid = function (props, touched) {
            props.validateForm().then(function (errors) {
                if (Object.keys(errors).length > 0) {
                    props.setTouched(touched);
                    if (document.querySelector('.form__group--error') !== null) {
                        setTimeout(function () {
                            document.querySelectorAll('.form__group--error')[0].scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        });
                    }
                }
                else {
                    _this.showSummary(props.values, props);
                }
            });
        };
        _this.onKeyDown = function (event) {
            if (event.target instanceof HTMLTextAreaElement)
                return;
            if ((event.charCode || event.keyCode) === 13 || event.code === "Enter") {
                event.preventDefault();
            }
        };
        _this.renderVenueInput = function (errors, touched, allowExists) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: getGroupClass(errors === null || errors === void 0 ? void 0 : errors.name, touched === null || touched === void 0 ? void 0 : touched.name), style: { display: _this.state.addressSelected ? "none" : " block" } },
                    React.createElement("label", { htmlFor: "VenueSearch", className: "form__label" }, "Venue Name"),
                    React.createElement(Field, { id: "VenueSearch", name: "venue.search" }, function (props) { return (React.createElement(Suspense, { fallback: React.createElement("div", { className: "form__control" }) },
                        React.createElement(VenueSearch, __assign({}, props, { onManualInput: function () { return _this.setState({ manualAddressInput: true }); }, onVenueSelected: _this.venueSelected, onVenueImage: _this.triggerGooglePhotoUpload, onVenueCleared: _this.venueCleared, onVenueExists: function (url) { return _this.setState({
                                venueAlreadyExists: true,
                                venueExistsURL: url
                            }); }, allowExists: allowExists })))); }),
                    React.createElement("div", { className: "form__direction" },
                        React.createElement("span", null, "Type in the venue name above, click on the address and we will do the rest!")),
                    (errors === null || errors === void 0 ? void 0 : errors.name) && (touched === null || touched === void 0 ? void 0 : touched.name) && (React.createElement("span", { className: "form__error" }, errors.name))),
                React.createElement("div", { className: "form__group", style: {
                        display: (_this.state.addressSelected || _this.state.manualAddressInput) ? 'block' : 'none'
                    } },
                    React.createElement(Field, { id: "venueName", name: "venue.name", type: "hidden" }),
                    React.createElement(Field, { id: "placeID", name: "venue.address.placeID", type: "hidden" }),
                    React.createElement(Field, { id: "latLng", name: "venue.address.latLng", type: "hidden" }),
                    React.createElement(Field, { id: "addressLine1", name: "venue.address.addressLine1", type: "hidden" }),
                    React.createElement(Field, { id: "addressLine2", name: "venue.address.addressLine2", type: "hidden" }),
                    React.createElement(Field, { id: "addressLine3", name: "venue.address.addressLine3", type: "hidden" }),
                    React.createElement(Field, { id: "county", name: "venue.address.county", type: "hidden" }),
                    React.createElement(Field, { id: "postcode", name: "venue.address.postcode", type: "hidden" }),
                    React.createElement(Field, { id: "website", name: "venue.website", type: "hidden" }),
                    React.createElement(Field, { id: "telephoneNumber", name: "venue.telephoneNumber", type: "hidden" }),
                    React.createElement(Field, { id: "image", name: "venue.image", type: "hidden" }),
                    React.createElement(Field, { id: "wordpress_post_id", name: "venue.id", type: "hidden" })),
                (_this.state.venue !== null) && (React.createElement(React.Fragment, null,
                    React.createElement(VenueOverview, { venue: _this.state.venue, ref: _this.venueOverview, hideShadow: true })))));
        };
        return _this;
    }
    return UserForm;
}(React.PureComponent));
export default UserForm;
