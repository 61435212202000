var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Lib
import * as React from "react";
import { getFormattedAddressFromObject } from "../lib/getFormattedAddress";
var VenueOverview = /** @class */ (function (_super) {
    __extends(VenueOverview, _super);
    function VenueOverview() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isAffixed: false
        };
        _this.wrapper = React.createRef();
        _this.concatTypes = function (terms) {
            if (typeof terms === "undefined" || !terms.length)
                return '';
            var termNames = terms.map(function (term) { return term.name; });
            return termNames.join(', ');
        };
        _this.getElementClass = function () {
            var classes = ["venue"];
            if (_this.props.hideShadow)
                classes.push('venue--no-shadow');
            return classes.join(' ');
        };
        _this.getWrapperClass = function () {
            var classes = [];
            if (_this.state.isAffixed)
                classes.push('affixed-venue');
            return classes.join(' ');
        };
        _this.render = function () {
            var _a, _b;
            if (_this.props.venue == null)
                return React.createElement(React.Fragment, null);
            var address = getFormattedAddressFromObject((_a = _this.props.venue) === null || _a === void 0 ? void 0 : _a.address, false);
            var venueClass = _this.getElementClass();
            var wrapperClass = _this.getWrapperClass();
            return (React.createElement("div", { className: wrapperClass, ref: _this.wrapper },
                React.createElement("div", { className: venueClass },
                    React.createElement("div", { className: "venue__image" },
                        React.createElement("img", { src: (_b = _this.props.venue.image) !== null && _b !== void 0 ? _b : 'https://www.allergycompanions.com/wp-content/uploads/2022/05/placeholder-venues.png', alt: "Alt Image" })),
                    React.createElement("div", { className: "venue__content" },
                        React.createElement("div", null,
                            (_this.props.venue.id) ? (React.createElement("a", { href: "/?post_type=venue&p=".concat(_this.props.venue.id), className: "venue__title h2" }, _this.props.venue.name)) : (React.createElement("h3", { className: "venue__title h2" }, _this.props.venue.name)),
                            React.createElement("span", { className: "venue__address" }, address))))));
        };
        return _this;
    }
    VenueOverview.prototype.componentDidMount = function () {
        var _a;
        // This is a relic from when the component worked differently (this component used to do the fetch request, but
        // needed to placed in multiple places on the same page so it made sense to move it to the parent component)
        // Still need to trigger onPlaceLoaded for formik values to be set in the parent component.
        if (typeof this.props.onPlaceLoaded === "function") {
            var formattedResp = __assign({}, this.props.venue);
            if (formattedResp.image === null) {
                formattedResp.image = (_a = this.props.venue.imageID) !== null && _a !== void 0 ? _a : null;
                delete formattedResp.imageID;
            }
            this.props.onPlaceLoaded(formattedResp);
        }
    };
    return VenueOverview;
}(React.PureComponent));
export default VenueOverview;
